import React, { useEffect, useRef } from "react"
import Glide, { Controls } from "@glidejs/glide/dist/glide.modular.esm"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

import "./style.zmm.scss"

import ContactModal from "../../../components/contactModal"
import { navigate } from "gatsby"

import ProductFunction1 from "/static/products/zhuangmanman/产品能力_1_v3.jpg"
import ProductFunction2 from "/static/products/zhuangmanman/产品能力_2.png"
import ProductFunction3 from "/static/products/zhuangmanman/产品能力_3.png"
import ProductFunction4 from "/static/products/zhuangmanman/产品能力_4.png"
import ProductFunction5 from "/static/products/zhuangmanman/产品能力_5.png"

import CoreAblity from "/static/products/zhuangmanman/核心优势.png"
import SpecificationBg from "/static/products/zhuangmanman/specification-bg.png"
import DiscountImg from "/static/products/zhuangmanman/discount.png"
import CaseImg1 from "/static/products/zhuangmanman/成功案例_1.png"
import CaseImg2 from "/static/products/zhuangmanman/成功案例_2.png"
import CaseImg3 from "/static/products/zhuangmanman/成功案例_3.png"
import LeftImg from "/static/products/zhuangmanman/左.png"
import RightImg from "/static/products/zhuangmanman/右.png"

const ZhuangManMan = ({ location }) => {
  const sliderRef = useRef(null)
  const specRef = useRef(null)
  const modalRef = useRef()

  useEffect(() => {
    const slider = new Glide(sliderRef.current, {
      type: "carousel",
    })
    slider.mount({ Controls })
    return () => {
      slider.destroy()
    }
  }, [])
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const ref = params.get("ref")
    if (ref) {
      window.dataLayer.push({ event: "ref", "Ref Company": ref })
    }
  }, [])

  return (
    <Layout>
      <ContactModal ref={modalRef} />
      <Seo
        title="智能装箱_智能装箱软件_集装箱模拟装箱软件-蓝胖智汇装满满"
        keywords="智能装箱,智能装箱软件,集装箱模拟装箱软件"
        description="蓝胖智汇专注自研智能装箱软件、集装箱模拟装箱软件，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="../../../images/products-banner.png"
          alt="智能装箱_智能装箱软件_集装箱模拟装箱软件-蓝胖智汇装满满"
          className="w-full"
        />
        <div className="absolute top-28 left-24 text-white text-3xl ">
          产品列表
        </div>
      </div>
      <div className="zmm-product">
        <div id="zmm-v2-leading-image">
          <h1 className="title">装满满-智能模拟装箱软件服务</h1>
          <div className="description">
            装箱配载SaaS平台，一键优化集装箱货车装载方式和托盘包装设计
          </div>
          <button
            type="button"
            className="btn"
            gtm-id="zmm-redirect-now"
            onClick={() =>
              specRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            立即了解
          </button>
        </div>
        <div id="zmm-v2-container">
          <div className="title">产品能力</div>
          <div className="card-wrapper">
            <div className="left"></div>
            <div className="card tabs">
              <input
                id="tab-1"
                type="radio"
                className="tab tab-selector"
                name="tab"
              />
              <label
                htmlFor="tab-1"
                className="tab tab-primary"
                gtm-id="zmm-product-slide"
              >
                配置丰富
              </label>
              <input
                id="tab-2"
                type="radio"
                className="tab tab-selector"
                defaultChecked={true}
                name="tab"
              />
              <label
                htmlFor="tab-2"
                className="tab tab-primary"
                gtm-id="zmm-product-slide"
              >
                方案直观
              </label>
              <input
                id="tab-3"
                type="radio"
                className="tab tab-selector"
                name="tab"
              />
              <label
                htmlFor="tab-3"
                className="tab tab-primary"
                gtm-id="zmm-product-slide"
              >
                信息连通
              </label>
              <input
                id="tab-4"
                type="radio"
                className="tab tab-selector"
                name="tab"
              />
              <label
                htmlFor="tab-4"
                className="tab tab-primary"
                gtm-id="zmm-product-slide"
              >
                降本提效
              </label>
              <input
                id="tab-5"
                type="radio"
                className="tab tab-selector"
                name="tab"
              />
              <label
                htmlFor="tab-5"
                className="tab last-tab tab-primary"
                gtm-id="zmm-product-slide"
              >
                灵活稳定
              </label>
              <div className="glider"></div>
              <section className="content">
                <div className="item" id="content-1">
                  <img
                    className="single-img"
                    src={ProductFunction1}
                    alt="装满满装箱软件装载规则"
                  />
                </div>
                <div className="item" id="content-2">
                  <img
                    className="single-img"
                    src={ProductFunction2}
                    alt="装满满装箱软件支持3D可视化"
                  />
                </div>
                <div className="item" id="content-3">
                  <img
                    className="single-img"
                    src={ProductFunction3}
                    alt="装满满装箱软件与ERP系统集成"
                  />
                </div>
                <div className="item" id="content-4">
                  <img
                    className="single-img"
                    src={ProductFunction4}
                    alt="装满满装箱软件降本增效"
                  />
                </div>
                <div className="item" id="content-5">
                  <img
                    className="single-img"
                    src={ProductFunction5}
                    alt="装满满装箱软件提高企业抗风险能力"
                  />
                </div>
              </section>
            </div>
            <div className="right"></div>
          </div>
        </div>
        <div id="zmm-v2-scenario">
          <div className="title">典型场景</div>
          <div className="content">
            <div className="card card1">
              <div className="title">海运拼柜</div>
              <div className="desc">
                根据拼柜优先级配单，
                快速生成批量货物的拼柜配载方案，提高拼柜作业效率和标准化程度
              </div>
            </div>
            <div className="card card2">
              <div className="title">循环取送</div>
              <div className="desc">
                根据循环取送 （milkrun） 计划，同时优化途径点访问顺序和装载方案
              </div>
            </div>
            <div className="card card3">
              <div className="title">重型设备</div>
              <div className="desc">
                合理安排重型设备的摆放方式和配载平衡， 提供详细操作指引，
                装卸报关一图搞定
              </div>
            </div>
            <div className="card card4">
              <div className="title">汽车配件</div>
              <div className="desc">
                综合优化配件打托包装设计和集装箱装载方案，提供深度系统集成服务赋能汽车供应链
              </div>
            </div>
            <div className="card card5">
              <div className="title">危化运输</div>
              <div className="desc">
                支持复杂货物堆码规则配置，限制危化品近距离摆放，严格执行各项运输安全规范，
                支持筒形货物和箱型货物混装
              </div>
            </div>
          </div>
        </div>
        <div id="zmm-v2-strength">
          <div className="title">核心优势</div>
          <div className="content">
            <div className="left">
              <div className="strength">
                <div className="title">强大的算法开发能力</div>
                <div className="desc">
                  基于自研优化算法引擎“蓝胖智汇
                  DoraOpt”开发，拥有全球领先的智能算法架构，有效结合AI数据驱动技术与运筹优化能力，求解复杂优化问题
                </div>
              </div>
              <div className="strength">
                <div className="title">专业的方案咨询实施</div>
                <div className="desc">
                  深耕订单交付与离场物流环节多年，涵盖售前估算、包装设计、入仓管理、配载运输优化等，实现
                  “销售-生产-运输” 的全链路整合优化
                </div>
              </div>
              <div className="strength">
                <div className="title">完善的IT服务支持</div>
                <div className="desc">
                  通过ISO27001信息安全认证，服务过多家世界500强和跨国集团公司，提供算法SDK、API集成、SaaS云计算和定制系统开发全栈式技术服务
                </div>
              </div>
            </div>
            <img className="right" src={CoreAblity} alt="核心优势" />
          </div>
        </div>
        <div id="zmm-v2-specifications" ref={specRef}>
          <div className="main-title">产品规格</div>
          <div className="cards">
            <div className="card card-1">
              <div className="card-header">
                <div className="title">精简版</div>
                <div className="content">轻量级装箱计算器</div>
                <img src={SpecificationBg} alt="底图" />
              </div>
              <div className="card-body">
                <ul className="desc">
                  <li>针对基本估柜计算需求</li>
                  <li>无需注册即开即用</li>
                  <li>基本3D可视化呈现</li>
                </ul>
                <div className="footer">
                  <button
                    className="action action1"
                    style={{ margin: "auto" }}
                    gtm-id="zmm-try-lite"
                    onClick={() =>
                      window.open("https://zmm-lite.doraopt.com?ref=h")
                    }
                  >
                    立即估柜
                  </button>
                </div>
              </div>
            </div>
            <div className="card card-2">
              <div className="card-header">
                <div className="title">专业版</div>
                <div className="content">专业级装箱配载SaaS平台</div>
                <img className="discount" src={DiscountImg} alt="限时折扣" />
                <img src={SpecificationBg} alt="底图" />
              </div>
              <div className="card-body">
                <ul className="desc">
                  <li>涵盖常见装载规则与装载模式</li>
                  <li>丰富3D可视化呈现与装载步骤指引</li>
                  <li>保存用户自定义数据库</li>
                  <li>重复试算对比最佳结果</li>
                </ul>
                <div className="footer">
                  <button
                    className="action action2"
                    style={{ margin: "auto" }}
                    gtm-id="zmm-register"
                    onClick={() => window.open("https://zmm.doraopt.com/")}
                  >
                    免费开始
                  </button>
                </div>
              </div>
            </div>
            <div className="card card-3">
              <div className="card-header">
                <div className="title">企业服务</div>
                <div className="content">根据客户需求提供定制化服务</div>
                <img src={SpecificationBg} alt="底图" />
              </div>
              <div className="card-body">
                <ul className="desc">
                  <li>支持私有化部署</li>
                  <li>定制开发特殊装载规则与业务流程</li>
                  <li>企业级运维支持</li>
                  <li>提供企业信息系统集成对接和运维服务</li>
                </ul>
                <div className="footer">
                  <button
                    className="action contact-us-now"
                    style={{ margin: "auto" }}
                    gtm-id="zmm-consult"
                    onClick={() => modalRef.current.openModal()}
                  >
                    马上咨询
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="zmm-v2-cases">
          <div className="title">成功案例</div>
          <div className="content">
            <div className="glide" ref={sliderRef}>
              <div className="glide__track" data-glide-el="track">
                <ul className="glide__slides">
                  <li className="glide__slide">
                    <div className="case-wrapper">
                      <img
                        src={CaseImg1}
                        className="img1"
                        alt="装满满装箱软件服务零售商超"
                      />
                      <div className="desc">
                        <div className="case-title">零售行业应</div>
                        <div className="case-desc">
                          <p>
                            蓝胖子与世界知名合同物流服务商深度合作，为跨国零售巨头解决大型海运集拼场景的集装箱拼柜难题。为其带来的效益如下：
                          </p>
                          <div className="row">
                            <div className="col">
                              <div className="first-line">
                                90<span>%</span>
                              </div>
                              <div className="second-line">货柜平均装载率</div>
                            </div>
                            <div className="col">
                              <div className="first-line">
                                8<span>分钟</span>
                              </div>
                              <div className="second-line">平均规划时间</div>
                            </div>
                            <div className="col">
                              <div className="first-line">
                                90<span>%</span>
                              </div>
                              <div className="second-line">人力成本节约</div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="check check1"
                          onClick={() => navigate("/cases/walmart")}
                        >
                          查看详情 <span> {">>"} </span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="glide__slide">
                    <div className="case-wrapper">
                      <img
                        src={CaseImg2}
                        className="img2"
                        alt="装满满装箱软件服务首航新能源"
                      />
                      <div className="desc">
                        <div className="case-title">新能源行业</div>
                        <div className="case-desc">
                          <p>
                            蓝胖子与首航新能源合作，为其提供智能装柜解决方案，成功替代人工CAD进行手工规划，提升规划效率，助力业务出海。为其带来的效益如下：
                          </p>
                          <div className="row">
                            <div className="col">
                              <div className="first-line">
                                12<span>%</span>
                              </div>
                              <div className="second-line">综合效率提升</div>
                            </div>
                            <div className="col">
                              <div className="first-line">
                                8<span>分钟</span>
                              </div>
                              <div className="second-line">平均规划时间</div>
                            </div>
                            <div className="col">
                              <div className="first-line">
                                100<span>%</span>
                              </div>
                              <div className="second-line">
                                替代人工CAD装柜规划
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="check check2"
                          onClick={() => navigate("/cases/shouhang")}
                        >
                          查看详情 <span> {">>"} </span>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="glide__slide">
                    <div className="case-wrapper">
                      <img
                        src={CaseImg3}
                        className="img3"
                        alt="装满满装箱软件服务化工物流"
                      />
                      <div className="desc">
                        <div className="case-title">能源化工行业</div>
                        <div className="case-desc">
                          <p>
                            蓝胖子与世界化工巨头巴斯夫合作，针对其亚太地区供应链运营提供了优化解决方案，减少业务流程，协助提升供应链透明度及订单响应效率。为其带来的效益如下：
                          </p>
                          <div className="row">
                            <div className="col">
                              <div className="first-line">
                                100<span>%</span>
                              </div>
                              <div className="second-line">估柜准确性</div>
                            </div>
                            <div className="col">
                              <div className="first-line">
                                8<span>分钟</span>
                              </div>
                              <div className="second-line">
                                规划方案安全合规性
                              </div>
                            </div>
                            <div className="col">
                              <div className="first-line">
                                100<span>%</span>
                              </div>
                              <div className="second-line">
                                订单履约效率提升
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="check check3">
                          查看详情 <span> {">>"} </span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="glide__arrows" data-glide-el="controls">
                <img
                  src={LeftImg}
                  className="glide__arrow glide__arrow--left arrow arrow-left"
                  data-glide-dir="<"
                  gtm-id="zmm-case-slide"
                  alt="left arrow"
                />
                <img
                  src={RightImg}
                  className="glide__arrow glide__arrow--right arrow arrow-right"
                  data-glide-dir=">"
                  gtm-id="zmm-case-slide"
                  alt="right arrow"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="misc">
          <div className="content">
            <div className="qa">
              <div className="title">其他问题</div>
              <div className="detail">
                告诉我们您需要解决什么问题，专家会帮助您找到最适合您的解决方案
              </div>
              <button
                className="contact-us-now"
                gtm-id="zmm-contact-us"
                onClick={() => modalRef.current.openModal()}
              >
                联系我们
              </button>
            </div>
            <div className="try">
              <div className="product">
                <div className="about">开始体验 装满满-精简版</div>
                <div
                  className="go check-zmm-lite"
                  onClick={() =>
                    window.open("https://zmm-lite.doraopt.com?ref=h")
                  }
                >
                  立即体验
                </div>
              </div>
              <div className="product">
                <div className="about">更多产品</div>
                <div
                  className="go check-products"
                  onClick={() => navigate("/products")}
                >
                  查看更多产品
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ZhuangManMan
